import React, { ChangeEvent, useState } from "react";
import { Grid, Menu } from "semantic-ui-react";

interface Props {
  patientList: JSX.Element;
  patientForm: JSX.Element;
  editMode: boolean;
  handleSearch: (str: string) => void;
}

export default function PatientsDashboard({
  editMode,
  patientList,
  patientForm,
  handleSearch
}: Props) {

  const [search, setSearch] = useState('');

  function handleOnChange(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const { value } = event.target;
    setSearch(value);
  }

  function handleOnClick(){
    console.log(search);
    handleSearch(search);
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="10">
          <Menu>
            <Menu.Menu position="right">
              <div className="ui right aligned category search item">
                <div className="ui transparent icon input">
                  <input
                    className="prompt"
                    type="text"
                    placeholder="Search by family"
                    onChange={handleOnChange}
                  />
                  <i onClick={handleOnClick} className="search link icon" />
                </div>
                <div className="results" />
              </div>
            </Menu.Menu>
          </Menu>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="10">{patientList}</Grid.Column>
        <Grid.Column width="6">{editMode && patientForm}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
