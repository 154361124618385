import React, { Fragment, useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { Container } from "semantic-ui-react";
import { SearchResponse } from "../models/searchResponse";
import { Patient, Resource } from "../models/patient";
import NavBar from "./NavBar";
import PatientsDashboard from "../../features/dashboard/PatientsDashboard";
import PatientForm from "../../features/form/PatientForm";
import PatientList from "../../features/dashboard/PatientList";
import { CreatePatient } from "../models/createPatient";
import {
  HealthcareGenerateTokenResponse,
} from "../models/tokenResponses";

function App() {
  const googleBaseUrl =
    "https://healthcare.googleapis.com/v1/projects/nicheaim-labs/locations/us-central1/datasets/test-patientholistics/fhirStores/test-patientholistics-datastore/fhir";
  const hapiBaseUrl = "https://hapi-fhir-dev.patientholistics.app/baseDstu3";
  const [patients, setPatients] = useState<Patient[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Resource | undefined>(
    undefined
  );
  const [currentAxiosConfig, setCurrentAxiosConfig] = useState<
    undefined | AxiosRequestConfig
  >(undefined);

  useEffect(() => {
    console.log("currentAxiosConfig: " + currentAxiosConfig);
    if (currentAxiosConfig === undefined) {
      getGoogleToken();
    } else {
      fetchPatients();
    }
  }, [currentAxiosConfig]);

  function getStandardAxiosConfig(token: string, disableCache?: boolean) {
    if (disableCache) {
      return {
        headers: {
          Authorization: "Bearer " + token,
          "Cache-Control": "no-cache",
        },
      };
    }

    return {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }

  function getGoogleToken() {
    const url =
      "https://us-central1-nicheaim-labs.cloudfunctions.net/test-cors";
    axios.get<HealthcareGenerateTokenResponse>(url).then((response) => {
      console.log(response.data);
      const axiosConfig = getStandardAxiosConfig(response.data.google_token);
      setCurrentAxiosConfig({ ...axiosConfig, baseURL: googleBaseUrl });
    });
  }

  function getHapiToken() {
    const url =
      "https://us-central1-nicheaim-labs.cloudfunctions.net/test-cors";
    axios.get<HealthcareGenerateTokenResponse>(url).then((response) => {
      console.log(response.data);
      const axiosConfig = getStandardAxiosConfig(response.data.hapi_token, true);
        setCurrentAxiosConfig({ ...axiosConfig, baseURL: hapiBaseUrl });
    });
  }

  function fetchPatients() {
    console.log(currentAxiosConfig);
    axios
      .get<SearchResponse>("/Patient", currentAxiosConfig)
      .then((response) => {
        console.log(response.data.entry);
        setPatients(response.data.entry);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function requestSearch(name: string) {
    const url = "/Patient" + "?family:exact=" + name;
    axios.get<SearchResponse>(url, currentAxiosConfig).then((response) => {
      console.log(response.data.entry);
      setPatients(response.data.entry);
    });
  }

  function searchPatients(name: string) {
    if (name) {
      requestSearch(name);
    } else {
      fetchPatients();
    }
  }

  function openForm() {
    setEditMode(true);
    console.log("click handleCreateNew");
  }

  function closeForm() {
    setEditMode(false);
    setSelectedPatient(undefined);
    console.log("click closeForm");
  }

  function handleSubmitCreateNew(data: CreatePatient) {
    console.log(data.name[0].family);
    console.log("click submit");

    if (data.id) {
      console.log("Resource with id");
      const url = "/Patient/" + data.id;
      axios.put(url, data, currentAxiosConfig).then((response) => {
        console.log(response);
        closeForm();
        fetchPatients();
      });
    } else {
      axios.post("/Patient", data, currentAxiosConfig).then((response) => {
        console.log(response);
        closeForm();
        fetchPatients();
      });
    }
  }

  function handleSearch(str: string) {
    console.log("click on search button");
    searchPatients(str);
  }

  function handleSelectedPatient(id: string) {
    console.log("Clicked select patient" + id);

    const url = "/Patient/" + id;
    axios.get<Resource>(url, currentAxiosConfig).then((response) => {
      console.log(response.data);
      setSelectedPatient(response.data);
      openForm();
    });
  }

  function handleDeletePatient(id: string) {
    console.log("Clicked handle delete patient" + id);

    const url = "/Patient/" + id;
    axios.delete(url, currentAxiosConfig).then((response) => {
      console.log(response);
      fetchPatients();
    });
  }

  function handleToggleHapiGoogle(toggleHapi: boolean) {
    console.log("toggle hapi " + toggleHapi);
    if (toggleHapi) {
      getHapiToken();
    } else {
      getGoogleToken();
    }
  }

  return (
    <Fragment>
      <NavBar
        handleCreateNew={openForm}
        handleToggleHapiGoogle={handleToggleHapiGoogle}
      />
      <Container style={{ marginTop: "7em" }}>
        <PatientsDashboard
          editMode={editMode}
          patientList={
            <PatientList
              selectPatient={handleSelectedPatient}
              handleDelete={handleDeletePatient}
              patients={patients}
            />
          }
          patientForm={
            <PatientForm
              selectedPatient={selectedPatient}
              closeForm={closeForm}
              handleSubmitCreateNew={handleSubmitCreateNew}
            />
          }
          handleSearch={handleSearch}
        />
      </Container>
    </Fragment>
  );
}

export default App;
