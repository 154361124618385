import React, { SyntheticEvent } from "react";
import { Button, Item, Segment } from "semantic-ui-react";
import { Patient } from "../../app/models/patient";

interface Props {
  patients: Patient[];
  selectPatient: (id: string) => void;
  handleDelete: (id: string) => void;
}

export default function PatientList({
  patients,
  selectPatient,
  handleDelete,
}: Props) {
  function handleOnClickDelete(
    e: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) {
    handleDelete(id);
  }
  return (
    <Segment>
      <Item.Group divided>
        {patients && patients.map((patient) => {
          if (patient.resource.name) {
            return (
              <Item key={patient.resource.id}>
                <Item.Content>
                  <Item.Header as="a">
                    {patient.resource.name[0].given[0]}
                  </Item.Header>
                  <Item.Meta>{patient.resource.birthDate}</Item.Meta>
                  <Item.Description>
                    <div>{patient.resource.name[0].family}</div>
                    <div>{patient.resource.gender}</div>
                  </Item.Description>
                </Item.Content>
                <Item.Extra>
                  <Button
                    name={patient.resource.id}
                    onClick={(e) => selectPatient(patient.resource.id)}
                    floated="right"
                    content="View"
                    color="green"
                  />
                  <Button
                    name={patient.resource.id}
                    onClick={(e) => handleOnClickDelete(e, patient.resource.id)}
                    floated="right"
                    content="Delete"
                    color="red"
                  />
                </Item.Extra>
              </Item>
            );
          }
        })}
      </Item.Group>
    </Segment>
  );
}
