import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Icon, Menu } from "semantic-ui-react";

interface Props {
  handleCreateNew: () => void;
  handleToggleHapiGoogle: (toggleHapi: boolean) => void;
}

export default function NavBar({
  handleCreateNew,
  handleToggleHapiGoogle,
}: Props) {
  const googleText = "You are using google change to hapi";
  const hapiText = "You are using hapi change to google";
  const [stateFhir, setStateFhir] = useState(googleText);
  const [stateToggle, setStateToggle] = useState(false);

  useEffect(() => {
    if (stateToggle) {
      setStateFhir(hapiText);
    } else {
      setStateFhir(googleText);
    }

    handleToggleHapiGoogle(stateToggle);
  }, [stateToggle]);

  function onClickToggleFhirState() {
    setStateToggle(!stateToggle);
  }

  return (
    <Menu inverted fixed="top">
      <Container>
        <Menu.Item>
          <Icon name="users" />
          Patients portal
        </Menu.Item>
        <Menu.Item>
          <Button onClick={onClickToggleFhirState} content={stateFhir} />
        </Menu.Item>
        <Menu.Item name="Patients" />
        <Menu.Item>
          <Button onClick={handleCreateNew} content="Create new" />
        </Menu.Item>
      </Container>
    </Menu>
  );
}
