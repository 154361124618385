import React, { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import {
  CreatePatient,
  SimpleCreatePatient,
} from "../../app/models/createPatient";
import { Name, Patient, Resource } from "../../app/models/patient";

interface Props {
  selectedPatient: Resource | undefined;
  handleSubmitCreateNew: (data: CreatePatient) => void;
  closeForm: () => void;
}

export default function PatientForm({
  selectedPatient,
  handleSubmitCreateNew,
  closeForm,
}: Props) {
  const initialState: SimpleCreatePatient = {
    name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
  };

  const [simplePatient, setSimplePatient] = useState(initialState);

  useEffect(() => {
    if (selectedPatient) {
      const selected: SimpleCreatePatient = {
        name: selectedPatient.name[0].given[0],
        last_name: selectedPatient.name[0].family,
        gender: selectedPatient.gender,
        date_of_birth: selectedPatient.birthDate,
      };
      setSimplePatient(selected);
    }
  }, []);

  function handleOnChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    setSimplePatient({ ...simplePatient, [name]: value });
  }

  function handleSubmit() {
    const newName: Name[] = [
      {
        family: simplePatient.last_name,
        given: [simplePatient.name],
        use: "official",
      },
    ];
    let newPatient: CreatePatient = {
      name: newName,
      gender: simplePatient.gender,
      birthDate: simplePatient.date_of_birth,
      resourceType: "Patient",
    };

    if (selectedPatient) {
      newPatient.id = selectedPatient.id;
    }

    handleSubmitCreateNew(newPatient);
  }

  return (
    <Segment clearing>
      <Form>
        <Form.Input
          placeholder="Name"
          value={simplePatient.name}
          name="name"
          onChange={handleOnChange}
        />
        <Form.Input
          placeholder="Lastname"
          value={simplePatient.last_name}
          name="last_name"
          onChange={handleOnChange}
        />
        <Form.Input
          placeholder="Gender"
          value={simplePatient.gender}
          name="gender"
          onChange={handleOnChange}
        />
        <Form.Input
          placeholder="Date of birth"
          value={simplePatient.date_of_birth}
          name="date_of_birth"
          onChange={handleOnChange}
        />
        <Button
          onClick={handleSubmit}
          floated="right"
          positive
          type="submit"
          content="Submit"
        />
        <Button
          onClick={closeForm}
          floated="right"
          type="button"
          content="Cancel"
        />
      </Form>
    </Segment>
  );
}
